import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'table/';

const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

class TableService {
  save(bill_id, game_id, data, table_id) {
    return axios.post(API_URLA + 'save/', {"bill_id": bill_id, "game_id": game_id, "data": data, "table_id": table_id}, config);
  }
  get(table_id) {
    return axios.post(API_URLA + 'get/', {"table_id": table_id}, config);
  }
  all(bill_id) {
    return axios.post(API_URLA + 'all/', {"bill_id": bill_id}, config);
  }
  clear_stat(table_id) {
    return axios.post(API_URLA + 'clear_stat/', {"table_id": table_id}, config);
  }
}

export default new TableService();
